.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --white: #fff;
  --pin-color: #e96f2c;
  --silver: #8492a6;
  --dark-grey: #333;
  --grey: #747474;
  --light-grey: #ececec;
  --brand-primary: #2d7aac;
  --slate: #3c4858;
  --black-shadow: rgba(0, 0, 0, 0.5);
  --white-overlay: rgba(255, 255, 255, 0.4);
}

body {
  background-color: var(--light-grey);
  font: 13px/20px PTSansRegular, Arial, Helvetica, sans-serif;
}

.find-a-home-content-description {
  color: var(--grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.headline {
  color: var(--dark-grey);
  font-style: normal;
  font-weight: bold;
  margin: 0 0 20px;
  font-size: 30px;
}

.vacancy-or-prelease-picker {
  flex-grow: 2;
  margin-left: 0;
}

.city-text-input {
  background-color: var(--white);
  border-radius: 5px;
  border-width: 0;
  box-shadow: 0 2px 4px 0 var(--black-shadow);
  color: var(--slate);
  flex-grow: 1;
  font-size: 18px;
  height: 50px;
  padding: 0 0 0 25px;
  width: 100%;
}

.list-picker-panel-container {
  /* @include box-shadow(0 3px 4px transparentize($black, 0.5)); */
  background: var(--white);
  border-radius: 0 0 5px 5px;
  color: var(--slate);
  left: 0;
  margin-top: 24px;
  position: absolute;
  top: 22px;
  width: 100%;
}

.list-picker-button {
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 var(--black-shadow);
  color: var(--slate);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 50px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.list-picker-item {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
  padding-top: 15px;
}

.list-picker-item:hover {
  background-color: var(--brand-primary);
  color: var(--white);
}

.list-picker-caret {
  fill: var(--dark-grey);
  margin-left: 10px;
  vertical-align: text-top;
  width: 12px;
}

.list-picker-text-input {
  background-color: var(--white);
  border: 1px solid var(--slate);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 var(--black-shadow);
  font-size: 14px;
  height: 40px;
  text-align: center;
  width: 130px;
}

.list-picker-text-input-container {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
}

.inwisconsin-submit-button {
  background-color: var(--brand-primary);
  border-color: var(--silver);
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 4px 0 var(--black-shadow);
  color: var(--white);
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  padding: 15px;
  width: 116px;
}

.rql-home-svg-content {
  height: 100%;
  position: relative;
  width: 100%;
}

.rql-home-svg-content svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 900;
}


.rql-secondary-container {
  position: relative;
}

.rql-home-svg-content text {
  fill: var(--white);
  font-size: 16px;
  pointer-events: none;
}

.rql-secondary-section {
  height: 580px;
  width: 100%;
}

.rql-home-region-area {
  cursor: pointer;
  pointer-events: all;
}

.rql-home-region-area:hover {
  fill: var(--white-overlay);
}

.region-pin {
  color: var(--pin-color);
  display: none;
  fill: var(--pin-color);
  pointer-events: none;
}

.region-text {
  display: none;
}

.region-pin-selected,
.region-text-selected {
  display: block;
}

.region-selected {
  fill: var(--white-overlay);
}

.align-right {
  float: right;
}

.powered-by-rentable {
  float: right;
  margin-right: 15px;
  width: 139px;
}

.homes-logo {
  background: transparent url("https://cdn1.static-homes.com/media/portalimgcache/widget/builder/logo-widget.png") 0 0
    no-repeat;
  direction: ltr;
  float: right;
  height: 22px;
  margin-right: 15px;
  overflow: hidden;
  text-indent: -3000px;
  width: 139px;
}

@media (max-width: 767px) {
  .city-text-input {
    width: 100%;
  }

  .inwisconsin-submit-button {
    width: 100%;
  }

  .list-picker-button {
    width: 100%;
  }

  .flex-spacers {
    flex-direction: column;
  }
}
